// import logo from './logo.svg';
import './App.css';
import { motion } from "framer-motion"


// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         {/* <img src={logo} className="App-logo" alt="logo" />  */}
//         <h1>
//           Coming Soon
//         </h1>
//         <a
//           className="App-link"
//           href="mailto:hey@shreshtharora.com"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           <h4>Email</h4>
//         </a>
//       </header>
//     </div>
//   );
// }

// export default App;


import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  // Link
} from "react-router-dom";




export default function App() {
  return (
    <Router>
      {/* <div>
        <nav>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/about">About</Link>
            </li>
            <li>
              <Link to="/users">Users</Link>
            </li>
          </ul>
        </nav> */}

        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Switch>
          {/* <Route path="/about">
            <About />
          </Route>
          <Route path="/users">
            <Users />
          </Route> */}
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      {/* </div> */}
    </Router>
  );
}

function Home() {
  return (
        <div className="App">
          <header className="App-header">
            {/* <img src={logo} className="App-logo" alt="logo" />  */}
            
            <motion.div 
              drag="x"
              dragConstraints={{ left: -100, right: 100 }}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
            >
            <h1>
              Shreshth Arora
            </h1>
            </motion.div>
           

            <motion.div 
              drag="x"
              dragConstraints={{ left: -50, right: 50 }}
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
            >
            <a
              className="App-link"
              href="mailto:hey@shreshtharora.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <h4>Email</h4>
            </a>
            </motion.div>
          </header>
        </div>
      );
}

// function About() {
//   return <h2>About</h2>;
// }

// function Users() {
//   return <h2>Users</h2>;
// }
